<template>
  <section>설비 모니터링</section>
</template>

<script>
export default {
  name: 'EquipmentMonitoring',
  props: [],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import 'EquipmentMonitoring';
</style>
